<template>
  <v-sheet class="d-flex flex-column transparent" light>
    <div class="main" style="position: relative">
      <aspect-ratio-layout>
        <div class="d-flex flex-column justify-center align-center;">
          <v-container fluid>
            <v-row justify="start" class="px-8">
              <v-col class="d-flex justify-start">
                <AppBackButton></AppBackButton>
              </v-col>
            </v-row>
          </v-container>
          <v-container
            style="height: 700px; width: 1200px; background: #29252c"
          >
            <v-row justify="center" class="pt-8">
              <v-col
                ><v-img
                  contain
                  height="62px"
                  src="https://vod.eventcdn.net/pandora/smc2021/pandoraman_logo%402x.png"
                ></v-img
              ></v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <iframe
                  :src="src"
                  frameborder="0"
                  style="min-height: 550px; width: 100%"
                ></iframe>
              </v-col>
              <v-col cols="6">
                <v-card elevation="0" color="transparent" class="white--text">
                  <v-card-title>Leaderboard:</v-card-title>

                  <v-card-text>
                    <v-list
                      dense
                      color="#808080"
                      style="overflow-y: auto; max-height: 430px"
                    >
                      <v-list-item
                        v-for="entry in leaderboard"
                        :key="entry.id"
                        class="align-center"
                      >
                        <div
                          class="
                            pink
                            black--text
                            font-weight-bold
                            d-flex
                            justify-center
                            align-center
                            my-0
                            mr-4
                          "
                          style="height: 30px; width: 30px"
                        >
                          {{ entry.position }}
                        </div>

                        {{ entry.username }} with score:
                        {{ entry.score }}
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </aspect-ratio-layout>
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import AspectRatioLayout from "../layouts/16by9Layout.vue";
export default {
  components: {
    AspectRatioLayout,
  },

  data() {
    return {
      src: `${process.env.BASE_URL}games/game1.html`,
      intervalId: null,
    };
  },

  computed: {
    ...mapState(["leaderboard"]),
  },

  async mounted() {
    // Setup message listener
    window.addEventListener("message", this.onGameOver, {
      passive: true,
    });

    await this.updateLeaderboard();

    // Refresh leaderboard every 30 secs
    this.intervalId = setInterval(async () => {
      await this.updateLeaderboard();
    }, 30000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
    window.removeEventListener("message", this.onGameOver);
  },

  methods: {
    ...mapActions(["updateLeaderboard"]),
    async onGameOver(e) {
      if (!e.origin === "https://pandora-smc2021.portal.eventcdn.com") return;

      if (e.data?.type === "GameOver") {
        console.log("GameOver message is:", e);
        // Refresh leaderboards
        await this.updateLeaderboard();

        // Notify user
        /*const message = `${e.data.data.message} New score is: ${e.data.data.score}`;
        this.$bvToast.toast(message, {
          title: e.data.data.is_new_highscore
            ? "New Highscore! 🥳"
            : "You didn't beat your highscore.. Try again!",
          toaster: "b-toaster-bottom-center",
          variant: "secondary",
          solid: true,
          appendToast: false,
          autoHideDelay: 10000,
        });*/
      }
    },
  },
};
</script>

<style scoped>
.main {
  background: linear-gradient(
      0deg,
      rgba(000, 000, 000, 0.7),
      rgba(000, 000, 000, 0.7)
    ),
    url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

@media screen and (min-width: 1020px) {
  .main {
    background: linear-gradient(
        0deg,
        rgba(000, 000, 000, 0.7),
        rgba(000, 000, 000, 0.7)
      ),
      url("https://vod.eventcdn.net/pandora/smc2021/MeetYourColleagues_Bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
  }
}
</style>
